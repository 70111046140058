// Tiêu chí
const CHU_DONG = 1;
const TAP_TRUNG = 2;
const TIEP_THU = 3;
const getNameTitle = new Map ([
    [CHU_DONG,'CHỦ ĐỘNG'],
    [TAP_TRUNG,'TẬP TRUNG'],
    [TIEP_THU,'TIẾP THU'],

])

// Danh mục
const PHAT_BIEU_HOI_BAI = 1;
const THAM_GIA_HOAT_DONG = 2;
const VUI_VE_HOA_DONG = 3;
const GHI_CHEP = 4;
const NGHE_GIANG = 5;
const TRA_LOI_TOAN_BO = 6;

// Đánh giá
const RAT_TOT = 1;
const TOT = 2;
const KHA = 3;
const KEM = 4;
const RAT_KEM = 5;

export {
    CHU_DONG,
    TAP_TRUNG,
    TIEP_THU,
    PHAT_BIEU_HOI_BAI,
    THAM_GIA_HOAT_DONG,
    VUI_VE_HOA_DONG,
    GHI_CHEP,
    NGHE_GIANG,
    TRA_LOI_TOAN_BO,
    RAT_TOT,
    TOT,
    KHA,
    KEM,RAT_KEM,
    getNameTitle
}