const CO_MAT = 1
const VANG_MAT = 0
const CO_PHEP = 2
const MHV = 100000

export {
    CO_MAT,
    VANG_MAT,
    CO_PHEP,
    MHV,
}