<template>
    <div class="bg-white p-3 p-md-5 rounded-lg">
        <h3 class="font-weight-bold">Nhận xét học viên buổi: {{ lesson }}</h3>
        <hr>
        <div class="grid-layout">
            <div class="p-3" style="background-color: #F0EDF7; border-radius: 8px">
                <h3 class="title-custom mt-2 mb-3">
                  SỐ HỌC VIÊN ĐÃ NHẬN XÉT:
                  <span style="color: red; font-weight: 600; font-size: 20px;">{{ quantityCommented?.length }}/{{studentList?.length }}</span>
                </h3>
                <hr class="bg-dark">
                <div v-if="studentList.length > 0">
                    <div class="d-flex mx-2 my-3 mx-md-4">
                        <div class="font-weight-bolder">Mã HV</div>
                        <div class="font-weight-bolder ml-4">Họ và tên</div>
                    </div>
                    <div class="height-content">
                        <div class="d-flex justify-content-between align-content-center bg-white mb-3 px-2 px-md-4 py-2 student-hover"
                            :class="student?.user_id === studentSelected?.user_id ? 'border-selected' : ''"
                            v-for="(student) in studentList" @click.prevent="selectStudent(student)"
                            style="border-radius: 8px" :key="student.user_id">
                            <span class="d-flex align-self-center">
                                {{ student?.user_id + MHV }}
                            </span>
                            <span class="w-100 mx-3 d-flex align-self-center">
                                {{ student?.name }}
                            </span>
                            <div class="d-flex justify-content-end">
                                <button v-if="student.isCompleted"
                                    style="gap: 4px; background: transparent !important; border: 0; color: #159400 !important; padding-right: 0 !important;"
                                    class="button-custom d-flex align-items-center">
                                    <img src="../../../../public/media/svg/check.svg" alt="icon-create">
                                    <span class="btn-text">Hoàn thành</span>
                                </button>
                                <button v-else-if="student?.status === 1" class="button-custom d-flex align-items-center"
                                    style="gap: 4px; background: transparent !important; border:0; color: #0066FF !important; padding-left: 0 !important; padding-right: 0 !important;">
                                    <img src="../../../../public/media/svg/edit.svg" alt="icon-edit">
                                    <span class="btn-text">Đang nhận xét</span>
                                </button>
                                <button v-else class="button-custom text-white" style="background-color: #544B7E;">
                                    Nhận xét
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border border-dark p-3 display-comment" style="border-radius: 8px; width: 100% !important;">
                <ValidationObserver ref="form" class="w-100" v-slot="{ handleSubmit }">
                <div class="d-flex justify-content-between align-content-center mb-2">
                    <h2 class="title-custom mt-2">Chi tiết nhận xét: {{ studentSelected?.name }}</h2>
                    <el-button
                        v-if="studentSelected?.isCompleted !== true"
                        style="padding: 5px !important; height: 30px; background: #544B7E; color: white;"
                        @click="handleSubmit(onSave)"
                        :disabled="loadingSave && checkDisable && !studentSelected?.comment_phase?.comment"
                    >
                        <span class="pt-2" style="padding: 6px 10px">Lưu nhận xét</span>
                    </el-button>
                </div>
                <hr class="bg-dark">
                <div class="height-content">
                  <div v-if="studentSelected?.attendance === CO_MAT">
                    <div
                        class="bg-base p-3 mb-3" v-for="(comment, index) in studentSelected?.comments"
                        :key="`comment-${index}`"
                        style="border-radius: 8px;"
                    >
                      <SelectOptionComment
                          :key="`comment-${index}`"
                          :student="studentSelected"
                          :comment="comment"
                          @change-input="changeInput"
                          @change-topic="updateDisableReview"
                      />
                    </div>
                  </div>
                    <div v-if="checkShowPhase && studentSelected?.comment_phase" class="bg-base rounded-lg p-3 mb-5">
                        <h6 class="font-weight-bold">NHẬN XÉT TỔNG QUAN GIAI ĐOẠN {{ getPhaseTime }}<span class="text-danger">*</span></h6>
                        <div class="line"></div>
                      <ValidationProvider
                          vid="phase-comment"
                          name="Nhận xét tổng quan về năng lực của học viên theo giai đoạn"
                          rules="required|min:150" v-slot="{ errors }"
                      >
                        <el-input type="textarea" style="word-break: break-word" :placeholder="`Nhập nhận xét tổng quan về năng lực của học viên giai đoạn ${getPhaseTime}`" :rows="3"
                            v-model="studentSelected.comment_phase.comment" :disabled="studentSelected.isCompleted"/>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                </div>
                </ValidationObserver>
            </div>
        </div>
        <!--  Modal Student Comment -->
        <el-dialog
            style="margin-top: 5vh !important;"
            :visible.sync="showModalComment" width="90%"
            :destroy-on-close="true"
            :title="`Chi tiết nhận xét: ${studentSelected?.name}`"
        >
          <ValidationObserver ref="form" class="w-100" v-slot="{ handleSubmit }">
            <div>
              <div class="bg-base p-3 mb-3" v-for="(comment, index) in studentSelected?.comments" :key="`comment-${index}`"
                   style="border-radius: 8px;">
                <SelectOptionComment
                    :key="`comment-${index}`"
                    :student="studentSelected"
                    :comment="comment"
                    @change-input="changeInput"
                    @change-topic="updateDisableReview"
                />
              </div>
              <div v-if="checkShowPhase && studentSelected?.comment_phase" class="bg-base rounded-lg p-3 mb-5">
                <h6 class="font-weight-bold">NHẬN XÉT TỔNG QUAN GIAI ĐOẠN {{ getPhaseTime }}<span class="text-danger">*</span></h6>
                <div class="line"></div>
                <ValidationProvider
                    vid="phase-comment"
                    name="Nhận xét tổng quan học viên theo giai đoạn"
                    rules="required|min:150" v-slot="{ errors }"
                >
                  <el-input type="textarea" :placeholder="`Nhập nhận xét tổng quan học viên giai đoạn ${getPhaseTime}`" :rows="3"
                            v-model="studentSelected.comment_phase.comment" />
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div slot="footer" class="dialog-footer d-flex justify-content-end mt-3">
              <el-button @click="closeModalComment" class="button-custom"
                         style="background: #E6E6E6 !important; color: #0a0a0a !important;">
                Hủy
              </el-button>
              <el-button v-if="studentSelected?.isCompleted !== true"
                         style="padding: 5px !important; height: 30px; background: #544B7E; color: white;" @click="handleSubmit(onSave)">
                <span class="pt-2" style="padding: 6px 10px">Lưu nhận xét</span>
              </el-button>
            </div>
          </ValidationObserver>
        </el-dialog>
        <el-dialog :width="widthScreen > 1024 ? '30%' : widthScreen > 767 ? '60%' : '80%'" :center="true" title="Nhắc nhở" :visible.sync="showConfirmChangeStudent">
            <div class="line" style="margin-top: -20px;"></div>
            <div class="d-flex flex-column">
                <label style="word-break: break-word">Bạn chưa hoàn thành nhận xét học viên <span
                        class="font-weight-bolder">{{ studentSelected?.name
                        }}</span>.</label>
                <label class="mt-4" style="word-break: break-word">Bạn có chắc chắn muốn chuyển nhận xét học viên khác không?</label>
            </div>
            <div slot="footer" class="dialog-footer d-flex justify-content-end">
                <el-button @click="cancelConfirm()" class="button-custom"
                    style="background: #E6E6E6 !important; color: #0a0a0a !important;">
                    Hủy
                </el-button>
                <el-button type="primary" @click="confirmChangeStudent" class="button-custom">Xác nhận</el-button>
            </div>
        </el-dialog>
        <!--Modal  Confirm Save Feedback-->
        <el-dialog :width="widthScreen > 1024 ? '30%' : widthScreen > 767 ? '60%' : '80%'" :center="true" title="Lưu kết quả nhận xét" :visible.sync="isSave">
            <div class="line" style="margin-top: -20px;"></div>
            <div class="d-flex flex-column">
                <label class="text-danger">Kết quả nhận xét sau khi lưu lại sẽ không thể thay đổi.</label>
                <label class="mt-2">Bạn có chắc chắn muốn lưu không?</label>
            </div>
            <div slot="footer" class="dialog-footer d-flex justify-content-end">
                <el-button @click="closeConfirmSave" class="button-custom"
                    style="background: #E6E6E6 !important; color: #0a0a0a !important;">
                    Hủy
                </el-button>
                <div class="position-relative ml-2">
                    <el-button :disabled="loading" class="button-custom" @click="onSubmit">
                        Xác nhận
                    </el-button>
                    <div class="loading-custom" v-if="loading">
                        <i class="el-icon-loading"></i>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ATTENDANCE_LESSON,
  GET_AUTO_COMMENT,
  POST_COMMENT_STUDENT
} from "@/core/services/store/classes/classes.module"
import { StudentComment, Comment } from "./model/StudentComment"
import SelectOptionComment from "./components/SelectOptionComment.vue"
import {
  PHAT_BIEU_HOI_BAI,
  THAM_GIA_HOAT_DONG,
  VUI_VE_HOA_DONG,
  GHI_CHEP,
  NGHE_GIANG,
  TOT,
} from "@/core/option/commentStudentType"
import moment from 'moment-timezone';
import {CO_MAT, VANG_MAT, CO_PHEP, MHV} from "@/core/option/attendanceStudent";

export default {
    data() {
        return {
            studentChange: null,
            loading: false,
            studentSelected: new StudentComment(),
            isSave: false,
            lesson: null,
            studentList: [],
            listTest: [],
            listLesson: [],
            lastComment: [],
            confirmChange: false,
            widthScreen: 0,
            showModalComment: false,
            CO_MAT: CO_MAT,
            CO_PHEP: CO_PHEP,
            VANG_MAT: VANG_MAT,
            MHV: MHV,
            loadingSave: true
        }
    },
    components: {
        SelectOptionComment
    },
    mounted() {
        let class_name = localStorage.getItem('className');
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: `Nhận xét học viên lớp: ${class_name}`, icon: 'far fa-tasks' }
        ]);
        this.lesson = this.$route.params.id
        this.getListStudent()
        this.listTest = JSON.parse(localStorage.getItem("listTest"))
        this.listLesson = JSON.parse(localStorage.getItem("listLesson"))
        this.sizeScreen()
    },
    computed: {
        showConfirmChangeStudent(){
          return this.studentList.find(d=>d.changeStudent) != null;
        },
        quantityCommented() {
            return this.studentList.filter(item => item?.isCompleted === true)
        },
        checkDisable() {
          return this.studentSelected?.comments?.find(item => item.review !== "" && item.review !== null) == null
        },
        checkShowPhase() {
            return this.getCurrentPhase !== undefined;
        },
        getPhaseTime() {
            const currentIndex = this.getPhaseDefault?.indexOf(this.getCurrentPhase);
            if (currentIndex === -1) {
                return "";
            }
            let startDate = "";
            if (currentIndex === 0) {
                startDate = this.listLesson?.find(d => true)?.date_only ?? "";
            }
            else {
                startDate = this.listLesson?.find(d => d.lesson === (this.getPhaseDefault[currentIndex - 1].lesson + 1))?.date_only ?? "";
            }

            return `${moment(startDate).format('DD/MM')} - ${moment(this.getCurrentPhase.date).format('DD/MM')}`;
        },
        getCurrentPhase() {
            const lesson = parseInt(this.$route.params.id)
            const phaseDefault = this.getPhaseDefault;
            return phaseDefault?.find(d => d.lesson === lesson);
        },
        getPhaseDefault() {
            const listDateLesson = this.listLesson?.map((date, index) => {
                return {
                    index: index,
                    lesson: date.lesson,
                    date: date.date_only,
                    type: date.type
                }
            })
            const phaseDefault = [6, 12, 18, 24, 28, 32, 36, 40]
            return phaseDefault.map(phase => {
                let current = listDateLesson?.find(lesson => lesson.lesson === phase)
                if (!current) {
                    return null
                }
                if (current.type === 'homework') {
                    return current
                }
                return listDateLesson?.find(lesson => lesson?.lesson === phase + 1)
            }).filter(d => d !== null && d !== undefined);
        },
        startPhaseTime() {
            const currentIndex = this.getPhaseDefault?.indexOf(this.getCurrentPhase);
            if (currentIndex === -1) {
                return "";
            }
            let startDate = "";
            if (currentIndex === 0) {
                startDate = this.listLesson?.find(d => true)?.date_only ?? "";
            }
            else {
                startDate = this.listLesson?.find(d => d.lesson === (this?.getPhaseDefault[currentIndex - 1].lesson + 1))?.date_only ?? "";
            }

            return startDate;
        },
        endPhaseTime() {
            return this.getCurrentPhase?.date;
        }
    },
    methods: {
      sizeScreen() {
        this.widthScreen = screen.width;
      },
      closeModalComment() {
        this.studentSelected.status = 0
        this.showModalComment = false
      },
      cancelConfirm() {
        let student = this.studentList.find(d => d.changeStudent);
        if (student) {
          student.changeStudent = false;
        }
      },
      changeInput(comment) {
        this.getAutoComment(comment).then(data => {
          this.loadingSave = false
          comment.recommendAuto = data.data.content;
        });
        this.updateDisableReview(comment);
      },
      closeConfirmSave() {
        this.isSave = false
        this.loading = false
      },
      confirmChangeStudent() {
        let studentChanged = this.studentList.find(d => d.changeStudent);
        studentChanged.changeStudent = false;
        this.studentSelected.status = 0;
        this.studentSelected = studentChanged;
        this.studentSelected.status = 1;
        if (this.widthScreen < 1024) {
          this.showModalComment = true
        }
      },
      generateEcoachTopic(title) {
        switch (title) {
          case 1:
            return [
              {
                label: "Phát biểu hỏi bài",
                value: PHAT_BIEU_HOI_BAI,
              },
              {
                label: "Tham gia hoạt động",
                value: THAM_GIA_HOAT_DONG,
              },
            ]
          case 2:
            return [
              {
                label: "Nghe giảng",
                value: NGHE_GIANG,
              },
            ]
          case 3:
            return null
        }
      },
      generateIEComment(comments, center_id) {
        if (comments==null || comments.length === 0) {
          if (center_id === 516) {
            return [
              new Comment(1, false, null, '', null, '',
                  [
                    {
                      label: "Phát biểu hỏi bài",
                      value: PHAT_BIEU_HOI_BAI,
                    },
                    {
                      label: "Tham gia hoạt động",
                      value: THAM_GIA_HOAT_DONG,
                    },
                    {
                      label: "Vui vẻ hoà đồng",
                      value: VUI_VE_HOA_DONG,
                    }
                  ],
              ),
              new Comment(2, false, null, '', null, '',
                  [
                    {
                      label: "Ghi chép",
                      value: GHI_CHEP,
                    },
                    {
                      label: "Nghe giảng",
                      value: NGHE_GIANG,
                    },
                  ],
              ),
              new Comment(3, false, null, '', null, '', null,),
            ];
          } else {
            return [
              new Comment(1, false, null, '', null, '',
                  [
                    {
                      label: "Phát biểu hỏi bài",
                      value: PHAT_BIEU_HOI_BAI,
                    },
                    {
                      label: "Tham gia hoạt động",
                      value: THAM_GIA_HOAT_DONG,
                    },
                  ]
              ),
              new Comment(2, false, null, '', null, '',
                  [
                    {
                      label: "Nghe giảng",
                      value: NGHE_GIANG,
                    },
                  ]
              ),
              new Comment(3, false, null, '', null, '', null),
            ];
          }
        }
        return comments.map(d => {
          return new Comment(d.title, false, d.topic, d.feedback, d.review, d.recommendAuto, this.generateTopic(d.title, center_id))
        });
      },
      generateIeltsTopic(title) {
        switch (title) {
          case 1:
            return [
              {
                label: "Phát biểu hỏi bài",
                value: PHAT_BIEU_HOI_BAI,
              },
              {
                label: "Tham gia hoạt động",
                value: THAM_GIA_HOAT_DONG,
              },
              {
                label: "Vui vẻ hoà đồng",
                value: GHI_CHEP,
              },
            ]
          case 2:
            return [
              {
                label: "Ghi chép",
                value: GHI_CHEP,
              },
              {
                label: "Nghe giảng",
                value: NGHE_GIANG,
              },
            ]
          case 3:
            return null
        }
      },
      generateTopic(title, center_id) {
        if (center_id === 516) {
          return this.generateIeltsTopic(title)
        } else {
          return this.generateEcoachTopic(title)
        }
      },
      getAutoComment(comment) {
        if (!comment.review) {
          return Promise.reject();
        }
        let payload = {
          lesson: parseInt(this.$route.params.id),
          classroom_id: parseInt(this.$route.query.classroom_id),
          user_id: 1,
          rate: comment.review,
          criteria: comment.title,
          subcategory: comment.topic ?? 6,
        }
        return this.$store.dispatch(GET_AUTO_COMMENT, payload);
      },
      async getListStudent() {
        this.studentSelected = await this.studentList.find(d => true);
        let response = await this.$store.dispatch(ATTENDANCE_LESSON, {
          classroom_id: parseInt(this.$route.query.classroom_id),
          lesson: parseInt(this.$route.params.id)
        });
        let data = response.data;
        const studentListPhase = data.map((item) => {
          return new StudentComment(
              item?.user_name,
              item?.user_id,
              item.comment_phase !== null || item?.comments !== null && item.comments.length > 0,
              false,
              this.$route.query.classroom_id,
              this.$route.params.id,
              item?.comment_phase,
              this.generateIEComment(item?.comments, item?.center_id),
              item.comment_phase !== null || item.comments != null && item.comments.length > 0,
              item?.attendance
          )
        })
        const studentListNormal = data.filter(item => item.attendance === CO_MAT).map(item => {
          return new StudentComment(
              item?.user_name,
              item?.user_id,
              item.comments != null && item.comments.length > 0,
              false,
              this.$route.query.classroom_id,
              this.$route.params.id,
              item?.comment_phase,
              this.generateIEComment(item?.comments, item?.center_id),
              item.comments != null && item.comments.length > 0,
              item?.attendance
          )
        })
        this.studentList = this.checkShowPhase ? studentListPhase : studentListNormal
        this.studentSelected = this.studentList?.find((item) => item?.isCompleted !== true) ?? this.studentList?.find(item => true);
        if (this.studentSelected) {
          this.studentSelected.status = 1;
        }
        if (this.studentSelected && this.widthScreen < 1024) {
          this.studentSelected.status = 1
          this.showModalComment = true
        }
      },
      onSave(){
        this.studentSelected?.comments?.find(item => {
          switch (this.checkShowPhase) {
            case true:
              if (this.studentSelected.attendance === CO_MAT) {
                if(item.review !== null) {
                  this.isSave = true
                }
              } else {
                this.isSave = true
              }
              break;
            case false:
              if (item.review !== null) {
                this.isSave = true
              }
          }
        })
      },
      onSubmit() {
        this.loading = true
        if(this.checkShowPhase && (this.studentSelected.attendance === 0 || this.studentSelected.attendance === 2)) {
          this.studentSelected.comments = null
        }
        let payload = {
          ...{
            ...this.studentSelected, comments: this.studentSelected?.comments?.map(d => {
              return {
                topic: d.topic === '' ? 6 : d.topic,
                review: d.review === '' ? null : d.review,
                disabled: d.disabled,
                feedback: d.feedback,
                nameTitle: d.nameTitle,
                recommendAuto: d.recommendAuto,
                title: d.title,
              };
            })
          },
          "comment_phase": !this.checkShowPhase ? null : {
            "comment": this.studentSelected?.comment_phase?.comment,
            "start": this.startPhaseTime,
            "end": this.endPhaseTime
          }
        }
        this.$store.dispatch(POST_COMMENT_STUDENT, payload).then((res) => {
          if (!res.error) {
            this.loading = false
            this.$message({
              message: 'Nhận xét thành công',
              type: 'success'
            });
            this.showModalComment = false
            this.isSave = false
            this.getListStudent()
          } else {
            this.$message.error('Oops, Đánh giá thất bại.');
          }
        });
      },
      selectStudent(student) {
        if (this.widthScreen < 1024) {
          if((this.studentSelected.isCompleted && student.isCompleted) || this.studentSelected.status === 1) {
            this.showModalComment = true
          }
          if(this.studentSelected.user_id && student.user_id && student.status === 0) {
            this.studentSelected.status = 1
            this.showModalComment = true
          }
        }
        if (student.user_id === this.studentSelected.user_id) {
          return;
        }
        if (this.studentSelected.isCompleted) {
          this.studentSelected = student;
          this.studentSelected.status = 1;
          return;
        }
        student.changeStudent = true;
      },
      updateDisableReview(comment) {
        if (!this.studentSelected || !comment) {
          return;
        }
        const hasGoodReview = this.studentSelected?.comments?.find(comment => comment.review > 0 && comment.review <= TOT);
        let commentReviews = this.studentSelected?.comments?.filter(comment => comment.title !== hasGoodReview?.title);
        commentReviews.forEach((comment) => {
          comment.reviews = comment.reviews.map((review) => {
            return {...review, disabled: hasGoodReview ? review.value <= 2 || review.disabled : false};
          });
        });
      },
    },
}
</script>

<style lang="scss" scoped>
.grid-layout {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 30px;
}

.loading-custom {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
}

.bg-base {
    background-color: #F0EDF7;
}

.student-hover:hover {
    cursor: pointer;
    opacity: 0.8;
}

.title-custom {
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: bolder;
}

.button-custom {
    padding: 5px 10px !important;
    border-radius: 6px;
    white-space: nowrap;
    background: #7956ee;
    color: white;
}

.border-selected {
    border: 1px solid #7F67CF;
    background: #ececf8 !important;
}

.display-comment {
  display: flex;
}

.el-dialog {
  margin-top: 5vh !important;
}

@media only screen and (max-width: 390px) {
  .height-content{
    height: 30vh !important;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 430px) {
  .height-content{
    height: 43vh !important;
    overflow-y: auto;
  }
  .btn-text {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .btn-text {
    display: flex;
  }
  .height-content{
    height: 50vh !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 820px) {
  .height-content{
    height: 53vh !important;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1023px) {
    .grid-layout {
        grid-template-columns: 12fr;
    }
    .title-custom {
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
    }
    .modal-comment {
      display: none;
    }
    .display-comment {
      display: none;
    }
}

@media only screen and (min-width: 1024px) {
  .height-content{
    height: 45vh !important;
    overflow-y: auto;
  }
  .btn-text {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .height-content{
    height: 43vh !important;
    overflow-y: auto;
  }
  .btn-text {
    display: flex;
  }
}

@media only screen and (min-width: 1560px) {
  .height-content{
    height: 50vh !important;
    overflow-y: auto;
  }
}
</style>
