<template>
  <div>
    <h6 class="font-weight-bold">{{ comment?.nameTitle }}</h6>
    <div class="line"></div>
    <div class="select-option">
      <el-select
          clearable
          :disabled="comment?.disabled || student.isCompleted"
          v-if="comment?.topics?.length > 0"
          v-model="comment.topic"
          placeholder="Chọn chủ đề chi tiết"
          size="small"
          @change="changeTopic()"
      >
        <el-option
            v-for="item in comment?.topics"
            :key="`topic-${comment.title}-${item.value}`"
            :label="item.label"
            :value="item.value"
            :disabled="item?.disabled"
        />
      </el-select>
      <el-tooltip class="item" effect="dark" placement="top">
        <template #content>
          <p class="tooltip-desktop">
            Trong 1 buổi, mỗi học viên chỉ tối đa một nhận xét đánh giá Tốt, Rất tốt.<br>
            Không được nhận xét Tốt, Rất tốt cho cùng 1 chủ đề cho 2 buổi học liên tiếp.
          </p>
          <p class="tooltip-mobile">
            Trong 1 buổi, mỗi học viên chỉ tối đa một nhận xét đánh giá Tốt, Rất tốt.
            Không được nhận xét Tốt, Rất tốt cho cùng 1 chủ đề cho 2 buổi học liên tiếp.
          </p>
        </template>
        <el-select
            clearable
            :disabled="comment?.disabled  || ( comment?.title !== 3 &&!comment?.topic) || student.isCompleted"
            v-model="comment.review"
            placeholder="Chọn đánh giá"
            size="small"
            @change="changeReview()"
        >
          <el-option
              v-for="item in comment?.reviews"
              :key="`review-${comment.title}-${item.value}`"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
          />
        </el-select>
      </el-tooltip>
    </div>
    <div class="d-flex flex-column mt-3"
         v-if="comment?.review !== null && comment?.review !== ''">
      <ValidationProvider vid="commentAuto" name="Nhận xét tự động"
                          :rules="`${ title !== 3 || title === 3 && review <= 2 ? 'required' : ''}`"
                          v-slot="{ errors, classes }">
        <el-input
            type="textarea"
            :disabled="comment?.disabled || student.isCompleted"
            :rows="2" v-model="comment.recommendAuto"
        />
        <div class="fv-plugins-message-container">
          <div data-field="comment-auto" data-validator="notEmpty"
               :class="classes"
               class="fv-help-block">{{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <label v-if="!comment?.disabled" style="color: #A17400; margin-top: 4px; max-height: 100px !important;">Giảng viên
        có thể chỉnh sửa lời nhận xét để cá nhân hóa cho
        mỗi học viên.</label>
    </div>
    <div class="mt-3" v-if="comment?.review > 0">
      <label>Góp ý cải thiện <span v-if="review > 2" class="text-danger">*</span></label>
      <ValidationProvider vid="feedback" name="Góp ý cải thiện" :rules="`${ review > 2 ? 'required|min:150' : ''}`"
                          v-slot="{ errors, classes }">
        <el-input :disabled="comment?.disabled || student.isCompleted"
                  type="textarea" :rows="2"
                  style="word-break: break-word"
                  v-model="comment.feedback"
                  placeholder="Nhập góp ý cải thiện"
        />
        <div class="fv-plugins-message-container">
          <div data-field="name" data-validator="notEmpty"
               :class="classes"
               class="fv-help-block">{{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>

export default {
  name: "SelectOption",
  data() {
    return {
      review: 0,
      title: 0
    }
  },
  props: {
    comment: {
      type: Object,
    },
    student: {
      type: Object,
    }
  },
  computed: {},
  mounted() {
    this.review = this.comment?.review;
    this.title = this.comment?.title
  },
  watch: {
    // whenever question changes, this function will run
    'comment.review'(newReview, oldReview) {
      this.review = newReview
    }
  },
  methods: {
    changeTopic() {
      this.comment.setReviews();
      this.$emit('change-topic', this.comment)
    },
    changeReview() {
      this.$emit('change-input', this.comment);
    },
  }
}
</script>

<style lang="scss" scoped>
.gap {
  gap: 15px;
}

.btn-close {
  padding: 6px;
  position: absolute;
  right: -6px;
  translate: -50%;
  cursor: pointer;
  background: transparent;
  border: none;
}

.select-option {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tooltip-desktop {
  height: 13px;
  display: flex;
}

.tooltip-mobile {
  display: none;
  width: 360px;
  height: 25px;
  word-break: break-word;
}

@media only screen and (max-width: 640px) {
  .select-option {
    display: flex;
    flex-direction: column;
  }
  .tooltip-desktop {
    display: none;
  }
  .tooltip-mobile {
    display: flex;
  }
}
</style>
